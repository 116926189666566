@import 'src/assets/styles/utils/index.module.scss';

.container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, 1fr);

  &.admin {
    grid-template-areas:
      'dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader'
      'communitySavings communitySavings energySupplyShare energySupplyShare tableBills tableBills tableBills tableBills'
      'carbonEmissions carbonEmissions carbonEmissions carbonEmissions tableBills tableBills tableBills tableBills'
      'selfSufficiency selfSufficiency selfConsumption selfConsumption homeEnergyInfo homeEnergyInfo homeEnergyInfo homeEnergyInfo'
      'energyProfile energyProfile energyProfile energyProfile energyProfile energyProfile energyProfile energyProfile';
  }

  &.member {
    grid-template-areas:
      'dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader dashboardHeader'
      'communitySavings communitySavings energySupplyShare energySupplyShare tableBills tableBills tableBills tableBills'
      'carbonEmissions carbonEmissions carbonEmissions carbonEmissions tableBills tableBills tableBills tableBills'
      'selfSufficiency selfSufficiency selfConsumption selfConsumption homeEnergyInfo homeEnergyInfo homeEnergyInfo homeEnergyInfo'
      'energyProfile energyProfile energyProfile energyProfile energyProfile energyProfile energyProfile energyProfile';
  }

  box-sizing: border-box;
  grid-gap: 14px;

  .dashboardHeader {
    grid-area: dashboardHeader;
  }

  .communitySavings {
    grid-area: communitySavings;
  }

  .energySupplyShare {
    grid-area: energySupplyShare;
    background-color: $color-vape;
  }

  .tableBills {
    grid-area: tableBills;
  }

  .selfSufficiency {
    grid-area: selfSufficiency;
  }

  .selfConsumption {
    grid-area: selfConsumption;
  }

  .homeEnergyInfo {
    grid-area: homeEnergyInfo;
  }

  .energyProfile {
    grid-area: energyProfile;
  }

  .liteWorldMap {
    grid-area: liteWorldMap;
  }

  .carbonEmissions {
    grid-area: carbonEmissions;
    grid-column: span 4;
    display: flex;
    flex-direction: row;

    .carbonFootprint {
      position: relative;
      border-radius: 0%;
    }

    .carbonSavings {
      border-radius: 0%;

      &::before {
        content: '';
        position: absolute;
        top: 10%;
        bottom: 10%;
        left: 0%;
        width: 4px;
        background: linear-gradient(to bottom, #66ee66, #fc1355);
        border-radius: 10px;
      }
    }
  }
}

.worldMap {
  position: relative;
  height: 100%;
}

.theMap {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.popupMini {
  margin-top: -30px;
}

@media only screen and (max-width: $xxl) {
  .container {
    grid-template-columns: repeat(4, 1fr);

    &.admin {
      grid-template-areas:
        'dashboardHeader dashboardHeader dashboardHeader dashboardHeader'
        'communitySavings energySupplyShare carbonEmissions carbonEmissions'
        'tableBills tableBills tableBills tableBills'
        'tableBills tableBills tableBills tableBills'
        'selfSufficiency selfConsumption homeEnergyInfo homeEnergyInfo'
        'energyProfile energyProfile energyProfile energyProfile';
    }

    &.member {
      grid-template-areas:
        'dashboardHeader dashboardHeader dashboardHeader dashboardHeader'
        'communitySavings energySupplyShare liteWorldMap liteWorldMap'
        'carbonEmissions carbonEmissions liteWorldMap liteWorldMap'
        'selfSufficiency selfConsumption homeEnergyInfo homeEnergyInfo'
        'energyProfile energyProfile energyProfile energyProfile';
    }
  }
}

@media only screen and (max-width: $xl) {
  .container {
    grid-template-columns: repeat(2, 1fr);

    &.admin {
      grid-template-areas:
        'dashboardHeader dashboardHeader'
        'communitySavings energySupplyShare'
        'carbonEmissions carbonEmissions'
        'tableBills tableBills'
        'selfSufficiency selfConsumption'
        'homeEnergyInfo homeEnergyInfo'
        'energyProfile energyProfile';
    }
    &.member {
      grid-template-areas:
        'dashboardHeader dashboardHeader'
        'communitySavings energySupplyShare'
        'carbonEmissions carbonEmissions'
        'tableBills tableBills'
        'selfSufficiency selfConsumption'
        'homeEnergyInfo homeEnergyInfo'
        'energyProfile energyProfile';
    }
  }
}
