@import 'src/assets/styles/utils/index.module.scss';

.itemsCenter {
  display: flex;
  align-items: center;
}

.nav_con {
  opacity: 1;
}

.centerAll {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: sticky;
  top: 0;
  left: 0;
  z-index: $z-header;
  width: 100%;
  height: $header-height;
  padding: 15px 53px 15px 32px;
  background-color: $color-white;
  backdrop-filter: blur(18px);
}

.blur {
  background-color: rgba(33, 30, 33, 0.4);
  backdrop-filter: blur(24px);
}

.blurAlt_1 {
  background-color: rgba($color-snow-in-april, 1);
  backdrop-filter: blur(14px);
}

.blurAlt_2 {
  background-color: rgba($color-metal-grey, 0.4);
  backdrop-filter: blur(14px);
}

@-moz-document url-prefix() {
  .background {
    background: rgba(255, 255, 255, 0.7);
  }
}

.profileMenu {
  z-index: $z-header + 10;
  min-width: 160px;
}

.shareMenu {
  z-index: $z-header + 10;
  min-width: 160px;
  margin-top: 27px;
}

.notLoggedContainer {
  padding-right: 20px;
  composes: container;
  background-color: $color-white;

  a {
    margin: 0 40px;

    &:first-child {
      margin-left: 0;
    }

    &.logo {
      svg {
        width: 62px;
        height: 27px;
      }
    }
  }
}

.LangContainer {
  background-color: rgba($color-metal-grey, 0.2);
  padding: 7px;
  border-radius: 2px;
  margin-right: 10px;
}

.title {
  font-weight: $fw-medium;
  font-size: 1.8rem;
  margin-left: 15px;
}

.hamburger {
  margin-right: 23px;
}

.burgerNavigation {
  position: absolute;
  top: 0;
  left: 0;
  // tmp
  z-index: 1000;
  width: 240px;
  background-color: rgba($color-white, 0.7);
  backdrop-filter: blur(18px);
}

.socialLink {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 42px;
  }
}

.socialIcon {
  margin-right: 20px;
}

.logoLink {
  display: block;
  margin-right: 22px;

  svg {
    width: 62px;
    height: 27px;
  }
}

.logoLinkNotLogged {
  margin-right: 53px;
}

.vertLine {
  border-left: thin solid $color-metal-grey;
  opacity: 0.7;
}

.vertLineA {
  height: 26px;
  margin-left: 22px;
  composes: vertLine;
}

.titleSideIcon {
  margin-left: 18px;
}

.scmNavLinks {
  margin-left: 4rem;

  @media (max-width: 1200px) {
    display: none;
  }
}

.backIcon {
  margin-left: 4px;

  &.disabled {
    opacity: 0.7;
    color: $color-metal-grey;
  }
}

.vertLineB {
  height: 12px;
  margin: 0 15px;
  composes: vertLine;
}

.vertLineC {
  height: 33px;
  margin: 0 10px;
  composes: vertLine;
}

.vertLineD {
  height: 23px;
  composes: vertLine;
}

.simBtn {
  background: none;

  svg {
    width: 10px;
    height: 10px;
  }
}

.aloneIcon {
  width: 16.5px;
  height: 16.5px;
}

.greenMenu {
  color: $color-oh-so-green;
}

.greenBtn {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 4px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: $color-oh-so-green;
    opacity: 0;
    transition: opacity 0.3s $ease-out-quad;
  }

  svg {
    position: relative;
  }

  & + & {
    margin-left: 12.5px;
  }
}

.greenBtnActive {
  &::before {
    opacity: 1;
  }
}

.group {
  margin-left: 5px;
  padding: 5px;
  border-radius: 4px;
  background: $color-snow-in-april;
}

.mla {
  margin-left: auto;
}

.abortBtn {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  border-radius: 4px;
  background: $color-metal-grey;
  color: $color-white;
}

.runPauseBtn {
  min-width: 175px;
}

.buildCommunityBtn {
  min-width: 190px;
}

.ml25 {
  margin-left: 25px;
}

.ml5 {
  margin-left: 5px;
}

.mr5 {
  margin-right: 5px;
}

.cancelBtn {
  margin-left: 15px;
}

.pageNavLink {
  & + & {
    margin-left: 32px;
  }
}

.loginLink {
  margin-right: 25px;
  color: $color-cod-grey;
  font-size: 1.4rem;
}

.registerLink {
  margin: 0 15px;
  color: $color-cod-grey;
  font-weight: $fw-medium;
  font-size: 1.4rem;
}

// Landing page header
.landingPageContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 21;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;

  nav {
    height: 60px;

    a {
      font-weight: $fw-regular;
      font-size: 1.6rem;
      font-family: $ff-sans;
      line-height: 1.9rem;
      transition: color 0.2s;
    }

    &.left {
      a {
        margin: 0 40px;

        &:first-child {
          margin-left: 30px;
        }

        &.logo {
          svg {
            width: 62px;
            height: 27px;
          }
        }
      }

      // &.white {
      //   a {
      //     color: $color-snow-in-april;
      //   }
      // }

      &.dark {
        a {
          color: black;
        }
      }
    }

    &.right {
      position: absolute;
      right: 0;

      a {
        margin: 0 40px;
      }

      a.startJourney {
        height: 60px;
        margin: 0;
        padding: 0 40px;
        background: $color-singularity-red;
        color: $color-snow-in-april;
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        font-family: $ff-sans;
        line-height: 60px;

        @media only screen and (min-width: 300px) and (max-width: 900px) {
          width: 100%;
          padding: 0 15px;
          text-align: center;
        }
      }
    }
  }
}

.menuColorLandingPageAlt_1 {
  color: $color-metal-grey !important;
}

.menuColorLandingPageAlt_2 {
  color: $color-ashy;
}

.rightItems {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 83px;

  nav {
    position: relative !important;
  }
}

.landingPageMenu {
  display: flex;
  flex: 1;

  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    display: none;
  }
}

.landingPageMenuResponsive {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10%;

  a {
    margin-bottom: 35% !important;
    margin-left: 0 !important;
    font-size: 1.9rem;
  }
}

.socialIconsResponsive {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 10%;

  a {
    min-width: 25px;
    margin-right: 20px !important;
  }
}

.burgerButton {
  display: none;

  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    display: flex;
  }
}

.menuItem {
  justify-content: right;
  padding: 12px;
  background-color: transparent;
  color: $color-white;
  font-weight: $fw-medium;
  font-size: 1.2rem;
  font-family: $ff-sans;
  line-height: 1.3rem;
}

.username {
  overflow: hidden;
  color: $color-oh-so-green;
  text-overflow: ellipsis;
}

.menuItemBottomBorder {
  border-bottom: 1px solid $color-kinda-dirty;
}

.closeBtn {
  position: absolute;
  top: 12px;
  right: 12px;
}

.boxIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  margin-right: 20px;

  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    display: none;
    align-items: center;
    justify-content: flex-start;
  }

  a {
    margin: 0 !important;
  }

  .icons {
    color: $color-snow-in-april;
  }

  .mailIcon {
    position: relative;
    top: 2px;
  }
}

.responsiveMenu {
  position: fixed;
  top: 60px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  height: 0%;
  padding-top: 20%;
  background-color: $color-pretty-dark;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.75s, visibility 0.75s, height 0.75s;
}

.open {
  height: 100vh;
  // animate nav opening details end
  opacity: 1;
  visibility: visible;
}

.helperTooltip {
  max-width: 200px;
  font-size: 1.2rem;
}

.notificationsModal {
  z-index: 9999 !important;
  max-height: 250px;

  .scmSimulationNotification {
    padding: 20px 40px;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $color-athens-gray;
  }
}

.notificationsModalList ul {
  max-height: 250px;
  overflow-y: auto;
}

.notificationsButton {
  position: relative;
  margin-left: 20px;
}

.notificationsBubble {
  position: absolute;
  right: -10px;
  bottom: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $color-singularity-red;
}

.notificationsBubbleText {
  display: block;
  color: $color-white;
  font-size: 1.1rem;
  line-height: 1.2rem;
}

.headerSimulationFlag {
  position: relative;
  margin: 0px;
  width: 120px;
  margin-left: 20px;
}

.headerTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 55px;

  .logoIcon {
    overflow: hidden;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    object-fit: contain;
    margin-left: 15px;
  }
}

.scmSingleSimulationNotification {
  position: absolute;
  top: 70px;
  width: 300px;
  padding: 20px 14px;
  background: black;
  border-radius: 10px;
  color: #ccc;
  right: 90px;
  // create a triangle with css in the ::before element
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid black;
  }

  .singleNotificationContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .singleCharacterContainer {
      font-size: 28px;
      padding-top: 10px;
    }
    span {
      display: flex;
      font-size: 12px;
      font-weight: 600;
    }
  }
}

.tooltipContent {
  width: 200px;
  font-family: $ff-sans !important;
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .mobileHeader {
    padding-left: 20px;
    padding-right: 20px;
  }
  .mobileTitleWrapper {
    background: #fff;
    z-index: 2;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
  }
}
